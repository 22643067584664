@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
    html {
        font-family: "Montserrat", system-ui, sans-serif;
    }
}

body {
    @apply bg-gray-100;
}

/* Card */
.card {
    @apply justify-between w-full px-4 py-2 mt-2 text-lg font-bold bg-white border border-b-2 rounded-lg border-slate-200;
}

.card-label {
    @apply text-sm text-slate-500;
}

.range {
    @apply relative text-xs p-0.5 text-center text-white rounded-full;
}

.buy-text {
    @apply text-green-600;
}

.buy-bg {
    @apply bg-green-700 bg-opacity-85;
}

.buy-bg-light {
    @apply bg-green-700 bg-opacity-10;
}

.sell-text {
    @apply text-red-600;
}

.sell-bg {
    @apply bg-red-700 bg-opacity-85;
}

.sell-bg-light {
    @apply bg-red-700 bg-opacity-10;
}

.neutral-text {
    @apply text-slate-400;
}

.form-input {
    @apply w-full px-3 py-2 text-gray-700 transition-all duration-300 border rounded bg-slate-100 focus:outline-none focus:bg-secondary-50 focus:ring-0 focus:ring-primary-200 focus:ring-opacity-50;
}


.label {
    @apply text-sm text-gray-600 dark:text-gray-200;
}


.btn-submit {
    @apply flex items-center justify-center w-full px-4 py-2 font-bold tracking-wide text-white transition-all duration-300 transform rounded-md bg-secondary-500 hover:bg-secondary-400 focus:outline-none focus:bg-secondary-400 focus:ring-1 focus:ring-primary-200 focus:ring-opacity-50;
}